import React from "react";
import "./Footer.css"; // Import CSS file for footer styling
import { Outlet, useNavigate } from "react-router-dom";
import { footerVariants, staggerChildren } from "../../utils/motion";
import { motion } from "framer-motion";

const Footer = () => {
  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    navigate(path);
  };
  return (
    <motion.footer
      style={{ backgroundColor: "yellow" }}
      initial="hidden"
      whileInView="show"
      variants={staggerChildren}
      viewport={{ once: false, amount: 0.25 }}
    >
      <motion.div className="container" variants={footerVariants}>
        <div className="footer-content">
          {/* Logo and name */}
          <div className="column">
            <h3 className="name">BetaHome.ng</h3>
            <p>Enviromentally conscious furniture</p>
          </div>
          {/* Quick links */}
          <div className="column">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <span onClick={() => handleLinkClick("/")}>Home</span>
              </li>
              <li>
                <span onClick={() => handleLinkClick("/service")}>
                  Services
                </span>
              </li>
              <li>
                {" "}
                <span onClick={() => handleLinkClick("/about")}>About</span>
              </li>
              <li>
                <span onClick={() => handleLinkClick("/contact")}>Contact</span>
              </li>
              <li>
                <span onClick={() => handleLinkClick("/policy")}>Policy</span>
              </li>
              <li>
                <span onClick={() => handleLinkClick("/terms")}>Terms</span>
              </li>
              <li>
                <span onClick={() => handleLinkClick("/product")}>Product</span>
              </li>
            </ul>
          </div>
          {/* Get in touch */}
          <div className="column">
            <h3>Get in Touch</h3>
            <p>Address: 135 Iju road, Church bus-stop, Lagos, Nigeria</p>
            <p>Email: help@betahome.ng</p>
            <p>Phone: +2349046966455</p>
          </div>
          {/* Follow us */}
          <div className="column">
            <h3>Follow Us</h3>
            <ul>
              <li>
                <a href="https://web.facebook.com/profile.php?id=100077497762239">
                  Facebook
                </a>
              </li>
              <li>
                <a href="#">Twitter</a>
              </li>
              <li>
                <a href="https://www.instagram.com/betahome.ng/">Instagram</a>
              </li>
              <li>
                <a href="https://www.youtube.com/@BetaHomeNg">Youtube</a>
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
      <Outlet />
    </motion.footer>
  );
};

export default Footer;
