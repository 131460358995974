import React from "react";
import OurServices from "../../Components/Service/Service";
import Services from "../../Components/Services/Services";
import Ambassador from "../../Components/Ambassador/Ambassador";

const Service = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <OurServices />
      <Services />
      <Ambassador />
    </div>
  );
};

export default Service;
