import { createReducer } from "@reduxjs/toolkit";
import { fetchCartSuccess } from "../actions/action";

const initialState = {
  cart: [],
};

const cartReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchCartSuccess, (state, action) => {
    state.cart = action.payload;
  });
});

export default cartReducer;
