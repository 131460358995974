import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Axios from "../../../constants/api_management/MyHttpHelperWithToken";
import "./Pro.css";
import Items from "../components/Body/Items/Items";

const Pro = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [initialPayment, setInitialPayment] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [agreementContent, setAgreementContent] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openTermsModal = () => {
    setTermsModalIsOpen(true);
    fetchAgreementContent(); // Fetch agreement content when terms modal opens
  };

  const closeTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  const handleConfirm = async () => {
    // Perform validation before sending data to the server
    if (!modeOfPayment || !initialPayment || !termsAgreed) {
      alert("Please fill in all required fields and agree to the terms.");
      return;
    }

    const PackageId = packageData?.data?.[2]?.id;
    const initialPaymentNumber = parseFloat(initialPayment);

    // Prepare data to send to the server
    const postData = {
      payment_mode: modeOfPayment,
      initial_payment: initialPaymentNumber,
      referee_code: referralCode,
      terms_accepted: termsAgreed,
      packageId: PackageId,
    };

    // Send data to the server
    setConfirmLoading(true);
    try {
      const response = await Axios.post("/packages/enrollments", postData);

      const responseUrl = response?.data?.data?.checkoutUrl?.authorization_url;
      setCheckoutUrl(responseUrl);
    } catch (error) {
      console.error("Error sending post request", error);
      closeModal();
    } finally {
      setConfirmLoading(false);
    }
  };

  const fetchPackageData = async () => {
    try {
      const response = await Axios.get("/packages");
      const data = response.data;
      // Store data in local storage
      localStorage.setItem("packageData", JSON.stringify(data));
      setPackageData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching package data:", error);
      setLoading(false);
    }
  };

  // Function to fetch agreement content
  const fetchAgreementContent = async () => {
    try {
      const response = await Axios.get("/terms/enrollment/");
      const content = response.data?.data?.content;
      // Store content in local storage
      localStorage.setItem("agreementContent", content);
      setAgreementContent(content);
    } catch (error) {
      console.error("Error fetching agreement content:", error);
    }
  };

  useEffect(() => {
    // Fetch data from local storage
    const storedPackageData = JSON.parse(localStorage.getItem("packageData"));
    const storedAgreementContent = localStorage.getItem("agreementContent");

    if (storedPackageData) {
      setPackageData(storedPackageData);
      setLoading(false);
    } else {
      // If no data in local storage, fetch it
      fetchPackageData();
    }

    if (storedAgreementContent) {
      setAgreementContent(storedAgreementContent);
    } else {
      // If no content in local storage, fetch it
      fetchAgreementContent();
    }
  }, []);

  const items = packageData?.data?.[2]?.items;

  const handleAcceptTerms = () => {
    setTermsAgreed(true);
    closeTermsModal();
  };

  return (
    <div className="package-container">
      {/* Display package data */}
      {loading ? (
        <p>Loading..</p>
      ) : (
        <>
          <h2 className="header">Pro Package</h2>
          <div className="banner">
            <span className="bannerText1">Beta</span>
            <span className="bannerText2">Pro</span>
          </div>
          <div>
            <h6
              style={{
                paddingTop: "5px",
              }}
            >
              Items
            </h6>
            <h3
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: "600",
              }}
            >
              Products contained in this package
            </h3>
            <div className="ItemHeader">
              <span>No of Items</span>
              <span>Features</span>
            </div>
            {items.map((item, index) => (
              <Items key={index} name={item.name} quantity={item.quantity} />
            ))}

            <div
              style={{
                display: "flex",
                gap: "80px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <span style={{ fontSize: "12px", fontWeight: "600" }}>
                Total Amount:
              </span>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  alignItems: "center",
                }}
              >
                ₦{packageData?.data?.[2]?.amount.toLocaleString()}
              </span>
            </div>
          </div>

          <button className="purchase-button" onClick={openModal}>
            Purchase Package
          </button>
        </>
      )}
      {/* Modal for subscription */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="react-modal"
      >
        <div>
          {checkoutUrl ? (
            <div className="checkout-url">
              <p>Click link to proceed!</p>
              <p>
                Proceed to payment:{" "}
                <a
                  className="checkout-link"
                  href={checkoutUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </p>
            </div>
          ) : (
            <div>
              <h2>Subscribe to Package</h2>
              <div>
                <label>
                  Choose Mode of Payment:
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="modeOfPayment"
                        value="daily"
                        onChange={(e) => setModeOfPayment(e.target.value)}
                      />
                      <span>Daily</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="modeOfPayment"
                        value="weekly"
                        onChange={(e) => setModeOfPayment(e.target.value)}
                      />
                      <span>Weekly</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="modeOfPayment"
                        value="monthly"
                        onChange={(e) => setModeOfPayment(e.target.value)}
                      />
                      <span>Monthly</span>
                    </div>
                  </div>
                </label>
              </div>
              <br />
              <label>
                Initial Payment:
                <input
                  type="number"
                  value={initialPayment}
                  onChange={(e) => setInitialPayment(e.target.value)}
                  style={{
                    border: "solid",
                    borderColor: "#bebebe",
                    borderRadius: "10px",
                  }}
                />
              </label>
              <br />
              <label>
                Referral Code:
                <input
                  type="number"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                  style={{
                    border: "solid",
                    borderColor: "#bebebe",
                    borderRadius: "10px",
                  }}
                />
              </label>
              <br />

              <div
                style={{ gap: "15px", display: "flex", flexDirection: "row" }}
              >
                <input
                  type="checkbox"
                  checked={termsAgreed}
                  onChange={() => {
                    setTermsAgreed(!termsAgreed);
                  }}
                />
                <span onClick={openTermsModal} style={{ cursor: "pointer" }}>
                  I agree to the Terms and Conditions.
                </span>
              </div>

              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button onClick={handleConfirm}>
                  {confirmLoading ? "Loading..." : "Confirm"}
                </button>
                <button onClick={closeModal}>Cancel</button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      {/* Second modal for terms content */}
      <Modal isOpen={termsModalIsOpen} className="terms-modal">
        <h2 style={{ textAlign: "center", fontWeight: "600" }}>
          Terms and Conditions
        </h2>
        <div className="terms-content">
          {/* Fetch and display agreement content here */}
          {agreementContent.split("\n").map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <button className="accept-terms-button" onClick={handleAcceptTerms}>
          Accept & Continue
        </button>
      </Modal>
    </div>
  );
};

export default Pro;
