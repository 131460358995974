import React, { useState } from "react";
import { AiOutlineMail, AiOutlineHome, AiOutlinePhone } from "react-icons/ai";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log(formData);
  };

  return (
    <div className="contact-us-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button className="contactButton" type="submit">
          Submit
        </button>
      </form>
      <div className="additional-contact-info">
        <div className="contact-info">
          <AiOutlineMail className="contact-icon" />
          <span>help@betahome.ng</span>
        </div>
        <div className="contact-info">
          <AiOutlineHome className="contact-icon" />
          <span>135 Iju road, Church bus-stop, Lagos, Nigeria</span>
        </div>
        <div className="contact-info">
          <AiOutlinePhone className="contact-icon" />
          <span>+2349046966455</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
