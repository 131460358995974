import React from "react";
import ContactUs from "../../Components/Contact/ContactUs";

const Contacts = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <ContactUs />
    </div>
  );
};

export default Contacts;
