import React from "react";
import "./AboutUs.css"; // Import CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <h2 style={{ color: "#03c04a" }}>Who Are We</h2>
        <p>
          As a Nigerian-based furniture marketing manufacturing company, our
          commitment lies in advocating reforestation, eco-friendly practices,
          and support for local artisans. BetaHome.ng is a service-based
          platform that creates environmentally conscious contemporary furniture
          helping individuals or corporate organizations decorate their homes or
          offices, providing state-of-the-art comfortable homes/offices, a place
          for comfort, rest, and relaxation. We sell new furniture and fittings
          at reasonable prices, Betahome.ng is an environmentally conscious
          marketplace that creates awareness of humans and the ecosystem by
          showcasing furniture made by skilled craftsmen for habitable living
          with earth consciousness in mind, interior decorations, Artwork and
          paintings, as well as facelifts and general rebranding. Our goal is to
          provide clients with high-quality, affordably priced furniture that
          adheres to contemporary design standards while also having a positive
          influence on the environment.
        </p>
        <p>
          We offer all of these services with a well-crafted and organized
          installment plan, a 90-day payment schedule that meets the needs and
          preferences of every person or corporate entity. The furniture
          produced by BetaHome.ng is made by skilled artisans who have received
          modern technical training. Home is frequently chosen as the preferred
          location to escape the daily commotion. Emphasizing changes and
          advancements in architectural design, custom living, and interior
          design will raise everyone's standard of living and business
          operations.
        </p>
        <p>
          90% of the raw materials required to meet our client’s daily needs are
          sourced locally, using locally accessible resources like reclaimed
          wood, bamboo, and recycled plastics. This supports local craftsmen by
          giving them a platform to showcase their abilities and promote their
          work. The effectiveness of our efforts is demonstrated by our
          popularity among environmentally conscious consumers in Nigeria and
          elsewhere. Only on special orders or a request from clients who want
          raw materials supplied from outside to meet their particular demand,
          the company has also won praise for its contribution to sustainable
          growth in the furniture sector locally.
        </p>
        <p>
          Our app is a very user-friendly platform for our potential
          client/users and is available for download on the app store. Once the
          app is downloaded to a device, there are multiple sign-up options
          including phone number, email, social media handle, or SMS. • Equipped
          with modern technology as the foundation of the products and services
          we offer, every piece of information user provide on the platform is
          highly secured on our server. Users must carefully review all of our
          products, terms & conditions, and choose the package that best meets
          their needs because each one has a different service functionality.
        </p>
      </div>
      <div className="about-us-image">
        <img src="service1.jpeg" alt="AboutUsImage" />
      </div>
    </div>
  );
};

export default AboutUs;
