import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; 
import "./Faq.css"
import { motion } from "framer-motion"
import { slideIn, staggerContainer, staggerChildren, footerVariants } from "../../utils/motion";

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.div
    initial="hidden"
      whileInView="show"
      variants={staggerChildren}
      viewport={{ once: false, amount: 0.25 }}
    >
     <motion.div className="faq"
      variants={footerVariants}
     >
      <h3 className="faq-question" onClick={toggleAnswer}>
        {question}
        <span className="icon">{isOpen? <FiChevronUp />: <FiChevronDown />}</span> 
      </h3>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </motion.div>
    </motion.div>
      
  );
};

export default FAQ;
