import React from "react";
import "./Items.css";

const Items = ({ name, quantity }) => {
  return (
    <div className="item">
      <span>{quantity}</span>
      <span>{name}</span>
    </div>
  );
};

export default Items;
