import React from 'react';
import './Service.css'; // Import CSS file for styling
import {motion} from "framer-motion"
import { slideIn, staggerChildren, } from '../../utils/motion';

const OurServices = () => {
  return (
    <motion.div
     initial="hidden"
      whileInView="show"
      variants={staggerChildren}
      viewport={{ once: false, amount: 0.25 }}
    >
    <motion.div>
    <motion.div className="service-container"
    >
      <motion.div className="service"
      variants={slideIn("right", "tween", 0.5, 1.4)}
      >
        <img
          src="closet1.jpg"
          alt="Service 1"
        />
        <div className="service-details">
          <h3>Interior Design</h3>
          <p>
            Our expert designers will transform your space into a beautiful
            and functional environment that reflects your personal style.
          </p>
        </div>
      </motion.div>
      <motion.div className="service"
      variants={slideIn("left", "tween", 0.5, 1.4)}
      >
        <img
          src="chair1.jpg"
          alt="Service 2"
        />
        <div className="service-details">
          <h3>Furniture Assembly</h3>
          <p>
            Let our skilled team handle the assembly of your new furniture
            quickly and efficiently, so you can start enjoying your space
            without the hassle.
          </p>
        </div>
      </motion.div>
      <motion.div className="service"
      variants={slideIn("right", "tween", 0.5, 1.4)}
      >
        <img
          src="chair5.jpg"
          alt="Service 3"
        />
        <img
          src="chair3.jpg"
          alt="Service 3"
        />
        <div className="service-details">
          <h3>Custom Furniture</h3>
          <p>
            Create a one-of-a-kind piece that perfectly suits your needs and
            style with our custom furniture design service.
          </p>
        </div>
      </motion.div>
      {/* Add more service components as needed */}
    </motion.div>
    </motion.div>
    </motion.div>
  );
};

export default OurServices;
