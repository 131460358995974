import React, { useState, useEffect } from "react";
import Axios from "../../constants/api_management/MyHttpHelper";
import "./Terms.css";

const Terms = () => {
  const [termsContent, setTermsContent] = useState("");

  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        // Fetch terms content from the endpoint
        const response = await Axios.get(`/terms`);
        const content = response?.data?.data?.content;
        // Store content in local storage
        localStorage.setItem("termsContent", content);
        // Set content in state
        setTermsContent(content);
      } catch (error) {
        console.error("Error fetching terms content:", error);
      }
    };

    // Always fetch terms content from the endpoint before setting it in local storage
    fetchTermsContent();

    const storedTermsContent = localStorage.getItem("termsContent");
    if (storedTermsContent) {
      setTermsContent(storedTermsContent);
    }
  }, []);

  return (
    <div className="terms-body">
      {termsContent.split("\n").map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
};

export default Terms;
