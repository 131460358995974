import React from "react";
import "./MissionVision.css";

const MissionVision = () => {
  return (
    <div className="missionVisionContainer">
      <div className="missionSection">
        <h2>Our Mission</h2>
        <p>
          We intend to beautify every rural and urban home within the
          communities in Nigeria, with Lagos as a flagship of the platform.
          Other cities to follow are Abuja, Port Harcourt, Anambra, and Kano and
          other states in Nigeria. Everyone’s home desires a touch of nature
          with a serene environment that feels like home to nature because home
          is where the heart goes after the day's hustles.
        </p>
        <p>
          We intend to create awareness with our daily radio program powered by
          the{" "}
          <span style={{ color: "green" }}>
            “Buy One! Plant One! Go-Green initiatives”.
          </span>{" "}
          Every member of the platform can talk about the services they render
          to humanity every day as a product or service if they wish to
          communicate with other community members of the platform to share
          their experiences while they showcase their skill sets and promote
          their product to other users. Other community members can also
          interact with you via live chat, live videos online on the app, and
          various social media channels online in real-time.
        </p>
        <p>
          We are dedicated to reforestation initiatives and their impacts on the
          ecosystem, understanding the effects of deforestation on Nigeria’s
          natural resources, and how it affects our everyday lives as humans,
          with our project called{" "}
          <span style={{ color: "green" }}>
            “Buy One! Plant One! Go-Green initiatives”
          </span>{" "}
          that will be launched and aired on our online radio station. Since the
          woods & timber industry supply 90% of the raw materials needed in our
          everyday furniture and domestic needs ranging from chairs, tables,
          beds, kitchen cabinets, wardrobes, etc., for each BetaHome.ng product
          sold, a percentage of it goes into the radio program to amplify the
          benefits of Good practice in the wood & Timber industry.
        </p>
        <p>
          Our dedication to sustainability, reforestation, and support for local
          artisans serve as an inspiration for entrepreneurs and businesses both
          in Nigeria and around the world. Our commitment to championing
          eco-friendly methods and preserving Nigeria’s cultural heritage
          through modern furniture manufacturing, reforestation mechanization,
          and education of local industry players. Health benefits for all
          involve from the local raw materials timber farmer to the customers
          purchasing the by-product, which is BetaHome.ng furniture exemplifies
          responsible business practices. The innovative and sustainable
          approach to contemporary furniture making demonstrates that green
          practices and profitable businesses can coexist.
        </p>
      </div>
      <img className="visionImage" src="banner1.png" alt="Vision" />
      <div className="visionSection">
        <h2>Our Vision</h2>
        <p>
          We aim to create value-chain opportunities through our platform for
          different sectors of the economy ranging from the art and creative
          industry, interior designs, technicians, artisans, carpenters, and
          furniture-makers, real-estate marketers and developers, semi-skilled
          persons, and vendors who produce or sell the raw materials needed to
          help every Nigerian home/office realize and achieve their dreams of
          living and working in a serene environment, even with their limited
          budget and spending power, hereby becoming a global one-stop service
          powered community for hiring or sourcing professionally qualified
          artisans for all works.
        </p>
      </div>
    </div>
  );
};

export default MissionVision;
