import React, { useState } from "react";
import "./Vendor.css";
import { motion } from "framer-motion";
import { staggerChildren, slideIn } from "../../utils/motion";

const Vendor = () => {
  const [showMore, setShowMore] = useState(false);

  const textContent = `Betahome.ng is one among the fastest growing companies in the industry with
   a reputation of the best team, highly skillful, knowledgeable trained personnel. 
   BetaHome.ng can be described as goal oriented, result driven and resourceful company. 
   Betahome.ng trained personnel are achievers who are result driven, we are  always focus on giving positive 
   return on investment, being resourceful this is why we can easily overcome our challenges, 
   as we are passionate and detailed oriented in this industry and also committed to making sure home 
   is where the heart.\n As a Vendors, Betahome.ng wants to partner with your company because we are passionate about 
   this industry and our quality align with your role, knowing that you are a forward thinking company with a 
   workplace culture where opportunities of manufacturing beautiful design as crafts, and as a company in our team,
   our skills and knowledge will be challenged and this will push us to collectively reach the hearts and home of 
   every individual with the mind set of home is where the heart goes.\n BetaHome.ng will bring the product closer to 
   the people, we will create a multi-purpose sales channel i.e expanding sales capacity to 
  encourage volume productivity, we also create an interface between the client and manufacturer.`;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <motion.div
      className="vendor-component"
      initial="hidden"
      whileInView="show"
      variants={staggerChildren}
      viewport={{ once: false, amount: 0.25 }}
    >
      <motion.div
        className="vendor-component-left"
        variants={slideIn("left", "tween", 0.5, 1.4)}
      >
        <h1>Become a Vendor</h1>
        <p>
          {showMore
            ? textContent
            : `${textContent.split(" ").slice(0, 99).join(" ")}...`}
        </p>
        {!showMore && (
          <span className="learn-more" onClick={toggleShowMore}>
            Learn More
          </span>
        )}
      </motion.div>
      <motion.div
        className="vendor-component-right"
        variants={slideIn("right", "tween", 0.5, 1.4)}
      >
        <button
          onClick={() => window.open("https://vendor.betahome.ng", "_blank")}
          className="call-to-action-button"
        >
          Become a Vendor
        </button>
      </motion.div>
    </motion.div>
  );
};

export default Vendor;
