import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FaShoppingCart } from "react-icons/fa";
import "./CartModal.css"; // Assuming you have CSS for styling the modal
import Axios from "../../constants/api_management/MyHttpHelperWithToken";

const CartModal = ({ isOpen, onRequestClose, onCloseAfterNavigation }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);

  const handleViewCart = () => {
    navigate("/view-cart"); // Navigate to the "ViewCart" screen
    onRequestClose(); // Close the modal immediately
    onCloseAfterNavigation(); // Close the modal
  };

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const response = await Axios.get(`/cart`);

        // Extract cart items and subtotal from response data
        const { items, total_amount } = response.data.data;

        // console.log("uuuu", response.data);
        setCartItems(items || []); // Ensure items is an array, or use an empty array if undefined
        setSubtotal(total_amount);
      } catch (error) {
        console.error("Error fetching cart:", error);
      }
    };

    if (isOpen) {
      fetchCart();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="cart-modal"
      overlayClassName="cart-modal-overlay"
      style={{
        content: {
          top: "calc(225px)",
          right: "10px",
          left: "unset",
          width: "200px",
          maxWidth: "200px",
          borderRadius: "5px",
        },
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <FaShoppingCart />
          <h3>Your Cart</h3>
          <button className="close-btn" onClick={onRequestClose}>
            &times;
          </button>
        </div>
        <div
          className="modal-body"
          style={{ paddingTop: "20px", maxHeight: "200px", overflowY: "auto" }}
        >
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <span>{item?.product?.name}</span>
              <span>₦{item?.product?.price}</span>
            </div>
          ))}

          <div
            className="cart-item"
            style={{ fontWeight: "600", paddingTop: "10px" }}
          >
            <span>Subtotal:</span>
            <span>₦{subtotal.toLocaleString()}</span>
          </div>
        </div>
        <div className="modal-footer">
          <button className="view-cart-btn" onClick={handleViewCart}>
            View Cart
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CartModal;
