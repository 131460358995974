import React from "react";

import AboutUs from "../../Components/AboutUs/AboutUs";
import VideoPlayer from "../../Components/videoPlayer/VideoPlayer";
import MissionVision from "../../Components/MissionVision/MissionVision";

const About = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <AboutUs />
      <VideoPlayer />
      <MissionVision />
    </div>
  );
};

export default About;
