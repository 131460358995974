import React from "react";
import "./HeaderBlock.css";

const PackageHeader = ({
  backgroundColor,
  packageName,
  onClick,
  firstSpanColor,
  secondSpanColor,
}) => {
  return (
    <div
      className="header-block"
      style={{
        backgroundColor: backgroundColor,
      }}
      onClick={onClick}
    >
      <span style={{ color: firstSpanColor, fontSize: "18px" }}>Beta</span>
      <span
        style={{ fontWeight: "600", fontSize: "24px", color: secondSpanColor }}
      >
        {packageName}
      </span>
    </div>
  );
};

export default PackageHeader;
