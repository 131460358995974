import "./App.css";
import React, { useState } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import About from "./Page/About/About";
import Header from "./Components/Header/Header";
import Home from "./Page/Home/Home";
import LoginPage from "./Page/authentication/view/LoginPage";
import CreateAccountPage from "./Page/authentication/view/CreateAccountPage";
import Contacts from "./Page/Contact/Contacts";
import Service from "./Page/Service/Service";
import Footer from "./Components/Footer/Footer";
import FAQs from "./Page/FAQs/FAQs";
import Policy from "./Page/Policy/Policy";
import Product from "./Page/Product/Product";
import Cart from "./Page/Cart/Cart";
import ForgotPasswordPage from "./Page/authentication/view/ForgotPasswordPage";
import ResetPasswordPage from "./Page/authentication/view/ResetPasswordPage";
import Success from "./Page/Payment/Success";
import Basic from "./Components/Package/Basic/Basic";
import Combo from "./Components/Package/Combo/Combo";
import Pro from "./Components/Package/Pro/Pro";
import Terms from "./Page/Terms/Terms";
import PackageDetails from "./Components/Package/Details/PackageDetails";

const App = () => {
  const location = useLocation();

  const shouldRenderHeaderAndFooter = () => {
    // Check if current path is LoginPage or CreateAccountPage
    return ![
      "/login",
      "/create-account",
      "/forgot-password",
      "/reset-password",
    ].includes(location.pathname);
  };

  return (
    <Provider store={store}>
      <div>
        {shouldRenderHeaderAndFooter() && <Header />}
        <Routes>
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/create-account" element={<CreateAccountPage />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/product" element={<Product />} />
          <Route path="/success-page" element={<Success />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/view-cart" element={<Cart />} />
          <Route path="/package-details/:id" element={<PackageDetails />} />
          <Route path="/basic-package" element={<Basic />} />
          <Route path="/combo-package" element={<Combo />} />
          <Route path="/pro-package" element={<Pro />} />
        </Routes>
        {shouldRenderHeaderAndFooter() && <Footer />}
      </div>
    </Provider>
  );
};

export default App;
