import React from "react";
import Hero from "../../Components/Hero/Hero";
import "./Home.css";
// import videoSrc from "../../asset/video3.mp4";
import FAQ from "../../Components/FAQ/Faq";
import { useNavigate } from "react-router-dom";
import { staggerContainer } from "../../utils/motion";
import { motion } from "framer-motion";
import OurServices from "../../Components/Service/Service";
import Package from "../../Components/Package/Package";
import Vendor from "../../Components/Vendor/Vendor";

const Home = () => {
  const navigate = useNavigate();

  const handleFaq = () => {
    window.scrollTo(0, 0);
    navigate("/faq");
  };
  return (
    <motion.div
      initial="hidden"
      whileInView="show"
      variants={staggerContainer}
      viewport={{ once: false, amount: 0.25 }}
      className="home"
    >
      <Hero />
      <div style={{ marginTop: "45px", marginBottom: "15px" }}>
        <Vendor />
      </div>
      <OurServices />

      <div>
        <h2
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
        >
          FAQ's
        </h2>
        <FAQ
          question="Is BetaHome.ng Free?"
          answer="Downloading the BetaHome.ng mobile app and creating an account are both completely free. However, you will be required to pay for each good and service that is provided to you over the platform. You have the option to choose anyone who fits your price range and budget from the wide range of product and service providers that are available."
        />
        <FAQ
          question="How do I purchase BetaHome.ng products?"
          answer="When you register to buy any of the products offered on the BetaHome.ng platform, you have the option of paying in full up front or using an installment payment plan of your choice."
        />
        <FAQ
          question="How Does It Work?"
          answer={`1. Choose the Product Package You Prefer:\n2. Sign the sales contract\n3. Select a payment plan\n4. Pay in instalments\n5. Select a shipping date.\n6. BetaHome.ng agents deliver the order and assemble it (between 5–10 working days).`}
        />
        <FAQ
          question="How do I make payment for BetaHome.ng Products?"
          answer="Every BetaHome.ng product and service must be paid for using the payment gateway offered and approved on the platform. In the case of an instant cash payment, customers or prospects may only make such payments to a third party (Beta Ambassador) with BetaHome.ng's prior approval and clearance to handle such payment transactions on the company's behalf."
        />

        <button className="faqButton" onClick={handleFaq}>
          See All FAQs
        </button>
      </div>
    </motion.div>
  );
};

export default Home;
