import React, { useState, useEffect } from "react";
import Axios from "../../constants/api_management/MyHttpHelperWithToken";
import CartItem from "../../Components/Cart/CartItem";
import "./Cart.css";

const CartView = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");

  // Fetch cart data from the backend
  const fetchCartData = async () => {
    try {
      const response = await Axios.get(`/cart`);

      // Extract cart items and subtotal from response data
      const { items } = response.data.data;
      setCartItems(items);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  // Delete item from the cart
  const handleDeleteItem = async (itemId) => {
    try {
      await Axios.delete(`/cart/${itemId}`); // Adjust the endpoint URL as per your backend
      // Update the cart items after deletion
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.id !== itemId)
      );
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  // Checkout function (you can implement as needed)

  const handleCheckout = async () => {
    setLoading(true);
    try {
      const itemIds = cartItems.map((item) => item.id);
      const paymentOption = "card_web";

      const response = await Axios.put("/cart/checkout", {
        items: itemIds,
        payment_option: paymentOption,
        path: "/success-page",
      });
      const responseUrl = response?.data?.data?.checkoutUrl?.authorization_url;

      setCheckoutUrl(responseUrl);

      // setCartItems([]);
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="cart-view">
      <h2>Your Cart</h2>
      {checkoutUrl ? (
        <div className="checkout-url">
          <p>Your order has been successfully placed!</p>
          <p>
            Proceed to payment:{" "}
            <a
              className="checkout-link"
              href={checkoutUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>
          </p>
        </div>
      ) : cartItems.length === 0 ? (
        <p>Your Shopping Cart is Empty</p>
      ) : (
        <>
          {cartItems.map((item) => (
            <CartItem
              key={item.id}
              item={item}
              onDelete={() => handleDeleteItem(item.id)}
            />
          ))}
          <div className="button-cont">
            <button
              className="checkout-btn"
              onClick={handleCheckout}
              disabled={loading}
            >
              {loading ? "Processing..." : "Checkout"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CartView;
