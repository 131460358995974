import React from "react";
import "./Services.css";
// import marketplaceImage from './images/marketplace.jpg';
// import userImage from './images/as_a_user.jpg';

const Services = () => {
  return (
    <div className="servicesCont">
      <div className="services">
        <div className="services-content">
          <h2 className="services-title">Marketplace</h2>
          <p className="services-description">
            Betahelp is a service-oriented community that supports local artists
            by allowing them to market their goods and showcase their abilities.
            Each user, raw material provider, and artisan can sign up on the
            site and list their areas of expertise so that other users can find
            and hire them. Each person registered on the site is a potential
            client or customer. All products are made by skilled artisans
            trained in modern techniques, with an emphasis on preserving
            Nigeria's rich cultural traditions and legacy while incorporating
            contemporary design elements. establishing a community on the
            platform where service-oriented individuals can interact with
            artisans who have been duly registered and verified to submit
            service requests.
          </p>
        </div>
      </div>
      <div className="services">
        <div className="services-content">
          <h2 className="services-title">As a User</h2>
          <p className="services-description">
            Users/clients on the app can sign in on the platform to become a
            member of the community, create a personal profile that they can use
            to interact with other users, vendors, technicians and artisans.
            They can search and find vendors that sell products that they desire
            or want, likewise, Users can find artisans to do any kind of
            household work like plumbing, electrical works, carpentry, AC,
            painting, flooring & tile installation, masonry, air conditioning
            installation and repairs, pest control, and other general services.
            Who are professional in delivering a certain kind of services that
            they want within their neighborhood, they can view comments on their
            profile, ratings, ranking and feedback on the previous services they
            rendered to other users, admin verification and recommendation of
            every one of them on the platform, they can request a quote from
            various technicians/artisans on the platform to compare price via
            rating for example; services, end-product and deliverables via
            ranking listing based on service deliverable on the platform,
            comments and feedback on their previous deliverables on the platform
            to other users, admin recommendations and verifications is highly
            advisable before they make an informed decision on which vendor they
            can purchase a product from or which technicians or artisans they
            wish to hire their services.
          </p>
        </div>
      </div>
      <div className="services">
        <div className="services-content">
          <h2 className="services-title">Vendor/Seller</h2>
          <p className="services-description">
            As a Vendor downloading the app and creating a profile gives you the
            opportunity to showcase and display your wares on the platform,
            likewise vendors/sellers can share the products that they display on
            the app directly to social media for far wider reach. Technicians,
            Artisans, skilled professionals, carpenters and furniture-makers,
            real-estate marketers and developers, semi-skilled persons and
            various users (Customers) of the platform can view, engage and
            interact with your products, as prospective clients they can use the
            app to search and find various items they can purchase from you on
            the platform.
          </p>
        </div>
      </div>
      <div className="services">
        <div className="services-content">
          <h2 className="services-title">Technicians/Artisans</h2>
          <p className="services-description">
            As a Technician, Artisans, skilled professionals, carpenter and
            furniture-makers, artists, interior designers, graphic artist,
            plumbers, electricians, home-service nurse,
            home-tutors/teachers/language instructors, painting, flooring & tile
            installation, masonry, air conditioning installation and repairs,
            pest control, other general services and semi-skilled persons, can
            download the app, create a user profile on the platform is a gateway
            for you to showcase your skills, talent, artwork, drawing, painting,
            sculptures to your target audience who are users(Customers) of the
            app that we have used some of your previous work of art and design
            to service them, by decorating their homes/offices with your
            beautiful craft and design which leaves a lasting impression on them
            the users. They can decide to patronize your craft or skillset to
            service their needs and want, for example they can decide you help
            them draw or paint a picture of someone dare to them as gift to show
            their appreciation, maybe to their 80 years old granny.
          </p>
          <ul>
            <li>
              You get paid once the customer attended to is satisfied your
              wallet will be credited immediately{" "}
            </li>
            <li>
              You get job recommendations from satisfied users(customers) on the
              app.
            </li>
            <li>
              You can also find verified vendors/ sellers of quality raw
              material goods that you need.{" "}
            </li>
            <li>
              You can also find interested persons who might want to learn about
              your craft or skills for a fee.
            </li>
            <li>
              You can also find fellow artisan that might need more hands to
              complete a job and share part of the income with you for the
              services provided.
            </li>
            <li>
              You earn a verified badge and star rating that gives you exclusive
              access to some top-rated service request on the platform before
              others.
            </li>
          </ul>
        </div>
      </div>
      {/* Add more service sections */}
    </div>
  );
};

export default Services;
