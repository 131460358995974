import React from "react";
import ProductList from "../../Components/ProductList/ProductList";
import "./Product.css";
import Package from "../../Components/Package/Package";

const Product = () => {
  return (
    <div className="product-page">
      <Package />
      <ProductList />
    </div>
  );
};

export default Product;
