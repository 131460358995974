import React, { useState } from "react";
import "./Product.css";
import Axios from "../../constants/api_management/MyHttpHelperWithToken";
import { AiOutlineEnvironment } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdCheckCircle } from "react-icons/md";
import PropTypes from "prop-types";

const Product = ({ product }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success"); // success or error
  const [alertMessage, setAlertMessage] = useState("");

  const handleAddToCart = async () => {
    setLoading(true); // Start loading
    try {
      const response = await Axios.put(`/cart`, {
        product: product.id,
        quantity: 1, // Hardcoded quantity
      });

      // Display custom alert if request is successful
      if (response.status === 200) {
        setShowAlert(true);
        setAlertType("success");
        setAlertMessage("Added to cart successfully");
      }
    } catch (error) {
      // console.error("Error adding to cart, make sure to Login:", error);
      setShowAlert(true);
      setAlertType("error");
      setAlertMessage(
        "Error adding to cart. Please try again later, make sure to login"
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const dismissAlert = () => {
    setShowAlert(false);
  };
  // console.log("Product:", product); // Add logging statement

  const { name, image, categories, price, quantity, description } = product;

  if (!product) {
    console.error("Product data is missing or invalid:", product);
    return <div>Product data is missing or invalid</div>;
  }

  return (
    <div className="product">
      {image && image.original && <img src={image.original} alt={name} />}
      <h3>{name}</h3>
      <p>{description}</p>
      <p>Price: ₦{price.toLocaleString()}</p>
      <p>Quantity: {quantity}</p>
      <div className="location">
        <span>
          <AiOutlineEnvironment style={{ marginRight: "5px" }} />
        </span>
        <span>
          <p>{product.shop[0]?.address?.street_address}</p>
        </span>
      </div>

      <div className="cart-button" onClick={handleAddToCart} disabled={loading}>
        {loading ? (
          <div className="spinner-loader"></div>
        ) : (
          <>
            <FaShoppingCart />
            <span>₦{price.toLocaleString()}</span>
          </>
        )}
      </div>

      {/* Custom alert */}
      {showAlert && (
        <div className={`custom-alert ${alertType}`}>
          <button className="dismiss-btn" onClick={dismissAlert}>
            &times;
          </button>
          <p>{alertMessage}</p>
          <div className="icon-container">
            {alertType === "success" ? (
              <MdCheckCircle className="icon" />
            ) : (
              <RiErrorWarningLine className="icon" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;

Product.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      original: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    price: PropTypes.number,
  }).isRequired,
};
