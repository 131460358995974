import React from "react";
import "./Policy.css";
import { staggerChildren, slideIn, fadeIn } from "../../utils/motion";
import { motion } from "framer-motion";

const Policy = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView="show"
      variants={staggerChildren}
      viewport={{ once: false, amount: 0.25 }}
      className="policy-container"
    >
      <motion.div className="policy-section">
        <motion.h2
          variants={fadeIn("right", "tween", 0.5, 1.4)}
          className="policy-title"
        >
          Framework
        </motion.h2>
        <motion.ul
          variants={slideIn("up", "tween", 0.5, 1.4)}
          className="policy-list"
        >
          <li>Every user of the app must be verified on the platform.</li>
          <li>
            All products and packages must have been 80% paid for on or before
            60 days after the client agrees to the terms of purchase.
          </li>
          <li>
            Clients/users/customers are to book pre-inspection and appointment
            dates for product delivery through the app.
          </li>
          <li>
            Balance of payment on each product package should be completed
            within the first 30 days after the delivery date.
          </li>
          <li>
            Failure to pay up the balance after 60 days of the delivery date
            attracts a default fine of 5% on each product.
          </li>
          <li>
            Failure to pay up the balance after 90 days from the delivery date
            attracts a default fee of 10% and product recall by the company.
          </li>
          <li>
            Payment information provided by the client when selecting a product
            cannot be changed.
          </li>
          <li>
            Sales Reps can monitor the payment status of their clients and
            reconcile it with their referral code to ascertain their referral
            percentage.
          </li>
        </motion.ul>
      </motion.div>
      <motion.div className="policy-section">
        <motion.h2
          variants={fadeIn("right", "tween", 0.5, 1.4)}
          className="policy-title"
        >
          Code of Conduct
        </motion.h2>
        <motion.ul
          variants={slideIn("down", "tween", 0.5, 1.4)}
          className="policy-list"
        >
          <li>
            Every payment must be done via the payment gateway provided by the
            platform.
          </li>
          <li>
            No client should make payment directly to a sales Rep (Sales
            Workforce Team) without the company's approval or consent.
          </li>
          <li>
            Clients that fail to pay up the balance as at when due will be
            fined, penalized, and reported to the appropriate authority for
            contract default.
          </li>
          <li>
            Court injunction will be taken if and when needed to retrieve the
            product from defaulting clients.
          </li>
          <li>
            All Sales Reps (sales workforce team) must publish their weekly
            sales report on their portal.
          </li>
          <li>
            Clients/users/customers should always double-check and confirm
            satisfaction before they make payment for any services on the
            platform.
          </li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default Policy;
