import React from 'react'
import FAQ from '../../Components/FAQ/Faq'
import "./FAQs.css"

const FAQs = () => {
  return (   
     <div className='container'
      >
          <h2 style={{textAlign:"center", fontWeight:"bold", fontSize:"20px"}}>FAQ's</h2>
      <FAQ
        question="Is BetaHome.ng Free?"
        answer="Downloading the BetaHome.ng mobile app and creating an account are both completely free. However, you will be required to pay for each good and service that is provided to you over the platform. You have the option to choose anyone who fits your price range and budget from the wide range of product and service providers that are available."
      />
      <FAQ
        question="How do I purchase BetaHome.ng products?"
        answer="When you register to buy any of the products offered on the BetaHome.ng platform, you have the option of paying in full up front or using an installment payment plan of your choice."
      />
      <FAQ
  question="How Does It Work?"
  answer={`1. Choose the Product Package You Prefer:\n2. Sign the sales contract\n3. Select a payment plan\n4. Pay in instalments\n5. Select a shipping date.\n6. BetaHome.ng agents deliver the order and assemble it (between 5–10 working days).`}
/>
      <FAQ
        question="How do I make payment for BetaHome.ng Products?"
        answer="Every BetaHome.ng product and service must be paid for using the payment gateway offered and approved on the platform. In the case of an instant cash payment, customers or prospects may only make such payments to a third party (Beta Ambassador) with BetaHome.ng's prior approval and clearance to handle such payment transactions on the company's behalf."
      />
      <FAQ
        question="How long does it take to complete payment for any BetaHome.ng Products? "
        answer="Customers or clients of BetaHome.ng may pay in full at once or over the course of a minimum of one month and a maximum of three months. 
Customers who pay BetaHome.ng in instalments must pay 80% up advance within two months before the products are delivered and 20% within thirty days after the delivery date. 
Customers and clients can choose from three different payment schedules: daily, weekly, and monthly, depending on their budget for installment payments
"
      />
      <FAQ
        question="How do I become a Beta Ambassador?"
        answer="
           By signing up to sell BetaHome.ng products and 
           services you can make money selling BetaHome.ng 
           products at your own convenience even while you work.
           You can become a beta ambassador of BetaHome.ng by using
           your personality to market and create brand awareness for
          BetaHome.ng products and services to a prospective 
          buyer/client for a commission."
      />
      <FAQ
        question="How do I make money as Beta Ambassador?"
        answer="
        You can earn money at your convenience by promoting BetaHome.ng products, and you are able to create a side hustle that will pay you a sizable commission bonus only for referring prospects or new customers to BetaHome.ng products using your referral code."
      />
      <FAQ
        question="What do I need to become a Beta Ambassador?"
        answer="
        As a beta ambassador, you must submit certain details that will be used to generate and create an individual profile that includes a referral code, your unique ID for claiming commission for each transaction made through your channel."
      />
      <FAQ
        question="How do I get a referral code?"
        answer="
        By completing the Beta Ambassador sign-up form with 
        complete information about yourself, which will be used to
        create a unique user ID for you to use as a referral code when
        introducing BetaHome.ng 
        products to potential new clients or customers."
      />
      <FAQ
        question="What do I need to sign up as Beta Ambassador?"
        answer="
        You must enter your name, phone number, bank information, and DOB, BVN, or NIN. Only verified beta ambassadors on the platform will receive the 
        commission bonus from BetaHome.ng; any beta ambassador 
        prospects who provide inaccurate or inappropriate information 
        will have their access to the platform suspended. Examples of 
        such information include phone number, date of birth, and bank account information."
      />
      <FAQ
        question="Will I find all kinds of service providers on BetaHelp?"
        answer="
        More than 200 services are presently listed 
        by BetaHelp on the site, and thousands more providers 
        are available to meet a variety of user needs. 
        As more areas are requested by users, updates are frequently 
        made to cover them. To fulfill the expanding user expectations, 
        more service providers (Partners) are joining every day.."
      />
      <FAQ
        question="Can I also register as a service provider on BetaHelp?"
        answer="
        Yes, as an unskilled worker, technician, merchant, or professional who provides services in exchange for payment. You may quickly create your service profile and payment wallet by registering as a Partner on betahelp. Once that process is complete, you may begin providing services. You can also become a vendor that sells the raw materials required to carry out such services from your business to clients nearby and earn money every day."
      />
      <FAQ
        question="Can I render any kind of service?"
        answer="
        With BetaHome.ng, you may help individuals 
        with their daily requirements and find solutions to issues. 
        You are welcome to join Betahelp as a partner if you 
        are able to use your abilities as a person to provide these 
        services that meet these requirements, lending your voice to 
        romote BetaHome.ng as a brand, their products, and services, 
        thereby persuading and convincing new potential customers to buy the products. You can also mentor and guide other people to sell BetaHome.ng products and services as a beta ambassador 
        for a commission bonus while attending to your clients' needs."
      />
      <FAQ
        question="BetaHome.ng is available on all android & iOS devices"
        answer="
        If your smartphone is an Android model, 
        go to the Google Play Store and download BetaHome.ng 
        to get started. If you use iOS, don't worry—we've got you 
        covered. 
        Visit the Apple Store and download BetaHome.ng to get started."
      />
      <FAQ
        question="Can I Choose the Delivery Date and Time?"
        answer="
        Of course, you get to pick the day and hour. 
        Please consult our customer service team by phone 
        or email at help@betahme.ng."
      />
       <FAQ
        question="Do I get a  refund if I stop installment payment for a product?"
        answer="
        Because BetaHome.ng is not a financial institution that allows deposits and withdrawals, you won't receive a refund for payments made to our corporate bank account. However, in the event that a customer is unable to meet the deadline for a payment obligation, BetaHome.ng will in that case revise and revamp the deadline in accordance with the client's specific request."
      />
       <FAQ
        question="Can I Create My Package?"
        answer="
        You may create a package based on your preferences, yes. The customer can place a special order for additional items in the color and pattern of their choice, and BetaHome.ng will send them a pricing quotation in response to their purchase request. 
Unable to find a good response? Inform us of your needs."
      />
      </div> 
  )
}

export default FAQs
