import React from "react";
import "./CartItem.css"; // Assuming you have CSS for styling the cart item

const CartItem = ({ item, onDelete }) => {
  return (
    <div className="cart-item">
      <div className="item-details">
        <h4>{item?.product?.name}</h4>
      </div>
      <div className="item-actions">
        <p>Price: ₦{item?.product?.price.toLocaleString()}</p>
        <button className="delete-btn" onClick={() => onDelete(item.id)}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default CartItem;
