import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import "./Header.css";
import CartModal from "../CartModal/CartModal";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLinkClick = (path) => {
    navigate(path);
    setShowMenu(false); // Close the menu after navigation
    setShowCartModal(false); // Close the cart modal after navigation
  };

  const toggleCartModal = () => {
    setShowCartModal(!showCartModal);
    setShowMenu(false);
  };

  const closeCartModal = () => {
    setShowCartModal(false);
  };

  return (
    <header>
      <div className="logo">
        <img src="BetaHome.png" alt="Betahome Logo" />
        <span
          style={{ fontWeight: "bold", color: "#03c04a", fontSize: "20px" }}
        >
          BetaHome.ng
        </span>
      </div>
      <div className="cart-icon" onClick={toggleCartModal}>
        <FaShoppingCart />

        <span>Cart</span>
      </div>
      <nav className={`menu ${showMenu ? "show" : ""}`}>
        <ul>
          <li className={location.pathname === "/" ? "active" : ""}>
            <span onClick={() => handleLinkClick("/")}>Home</span>
          </li>
          <li className={location.pathname === "/service" ? "active" : ""}>
            <span onClick={() => handleLinkClick("/service")}>Service</span>
          </li>
          <li className={location.pathname === "/product" ? "active" : ""}>
            <span onClick={() => handleLinkClick("/product")}>Product</span>
          </li>
          <li className={location.pathname === "/about" ? "active" : ""}>
            <span onClick={() => handleLinkClick("/about")}>About</span>
          </li>
          <li className={location.pathname === "/contact" ? "active" : ""}>
            <span onClick={() => handleLinkClick("/contact")}>Contact</span>
          </li>
          <li>{/* Cart Icon */}</li>
        </ul>
      </nav>
      <div className={`menu-icon`} onClick={toggleMenu}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      {/* Cart Modal */}
      <CartModal
        isOpen={showCartModal}
        onRequestClose={closeCartModal}
        onCloseAfterNavigation={() => setShowCartModal(false)}
      />
    </header>
  );
};

export default Header;
