import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Axios from "../../constants/api_management/MyHttpHelper";
import "./Ambassador.css";

const Ambassador = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [repsTerms, setRepsTerms] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openTermsModal = () => {
    setTermsModalIsOpen(true);
    // fetchAgreementContent(); // Fetch agreement content when terms modal opens
  };

  const closeTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  const fetchAgreementContent = async () => {
    try {
      const response = await Axios.get("/terms/ambassador/");
      const content = response.data?.data?.content;
      // Store content in local storage
      localStorage.setItem("repsTerms", content);
      setRepsTerms(content);
    } catch (error) {
      console.error("Error fetching agreement content:", error);
    }
  };
  const handleConfirm = async () => {
    // Perform validation before sending data to the server
    if (!termsAgreed) {
      alert("Please fill in all required fields and agree to the terms.");
      return;
    }

    // Prepare data to send to the server
    const postData = {
      referee_code: referralCode,
      terms_accepted: termsAgreed,
    };

    // Send data to the server
    setConfirmLoading(true);
    try {
      const response = await Axios.post("/user/sales-rep/requests/", postData);
      closeModal();
      alert(
        "Your request is successful, our admin will will get in touch with you"
      );
    } catch (error) {
      closeModal();
      alert(error.response.data.message);
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data from local storage

    const storedAgreementContent = localStorage.getItem("repsTerms");

    if (storedAgreementContent) {
      setRepsTerms(storedAgreementContent);
    } else {
      // If no content in local storage, fetch it
      fetchAgreementContent();
    }
  }, []);

  const handleAcceptTerms = () => {
    setTermsAgreed(true);
    closeTermsModal();
  };

  return (
    <div className="ambassador-container">
      <h2 className="ambassador-title">Become an Ambassador</h2>
      <p className="ambassador-description">
        Betahome.ng Ambassador is an opportunity to become a member of the
        community that renders services with their skills in sales and
        marketing. They can use these skills to educate, promote, and create
        brand awareness about the product and its benefits to the public.
      </p>
      <p className="ambassador-description">
        As a Betahome.ng Ambassador, for every prospective client that you pitch
        the products on the platform to and they end up downloading the app, you
        earn a percentage on every product purchase that has your referral code
        on it. Your unique ID number is very important as it serves as your
        login ID, giving you backend access to a portal where you can track all
        your activities as a betahome.ng ambassador.
      </p>
      <p className="ambassador-description">
        You also earn a percentage when you make the most sales for the every by
        becoming the Team Lead of the month, and on net sales for the first
        month from every new betahome.ng ambassador that signs up with your
        referral code.
      </p>
      <h3 className="ambassador-subtitle">
        Join the Betahome.ng Ambassador Team
      </h3>
      <p className="ambassador-description">
        Requirements to join the workforce to sell the products and services of
        the platform include basic verification procedures such as Name, Phone
        number and Email address.
      </p>
      <ul className="ambassador-list">
        <li>Name</li>
        <li>Phone Number</li>
        <li>Email Address</li>
      </ul>
      <p className="ambassador-description">
        All this verification information is important and needed to generate a
        unique identification number (which also serves as your user's login and
        referral code) before an individual can become a betahome.ng ambassador
        to sell the various products and services on the platform to earn a
        percentage.
      </p>

      <div>
        <button className="join-now-button" onClick={openModal}>
          Join Now
        </button>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="react-modal"
        >
          <div>
            <h2>Become an Ambassador</h2>
            <div>
              <label>
                Referee Code:
                <input
                  type="number"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                  style={{
                    border: "solid",
                    borderColor: "#bebebe",
                    borderRadius: "10px",
                  }}
                />
              </label>
              <br />
              <div
                style={{ gap: "15px", display: "flex", flexDirection: "row" }}
              >
                <input
                  type="checkbox"
                  checked={termsAgreed}
                  onChange={() => {
                    setTermsAgreed(!termsAgreed);
                  }}
                />
                <span onClick={openTermsModal} style={{ cursor: "pointer" }}>
                  I agree to the Terms and Conditions.
                </span>
              </div>

              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button onClick={handleConfirm}>
                  {confirmLoading ? "Loading..." : "Join"}
                </button>
                <button onClick={closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={termsModalIsOpen} className="terms-modal">
          <h2 style={{ textAlign: "center", fontWeight: "600" }}>
            Terms and Conditions
          </h2>
          <div className="terms-content">
            {/* Fetch and display agreement content here */}
            {repsTerms.split("\n").map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
          <button className="accept-terms-button" onClick={handleAcceptTerms}>
            Accept & Continue
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default Ambassador;
