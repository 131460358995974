import React from "react";
import "./Success.css";
import { MdCheckCircle } from "react-icons/md";

const Success = () => {
  return (
    <div className="success">
      <h3>Payment Successful</h3>
      <MdCheckCircle className="icon" size={35} />
    </div>
  );
};

export default Success;
