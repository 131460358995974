// reducers.js
import { createReducer } from "@reduxjs/toolkit";
import { fetchProductsSuccess } from "../actions/action";

const initialState = {
  products: [],
};

const productReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchProductsSuccess, (state, action) => {
    state.products = action.payload;
  });
});

export default productReducer;
