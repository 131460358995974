import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import './VideoPlayer.css'

const VideoPlayer = () => {



  return (
    <div className="videoPlayer">
      <div className="videoContainer">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=esyptG_0At4&pp=ygUIYmV0YUhvbWU%3D"
          controls={true}
          className="Player"
        />
      </div>

      <div className="content">
        <p className="contentp">
          We have developed a robust platform that is dedicated to giving every
          service-oriented individual and every deserving Nigerian who can
          provide a service with a way to make a living; our platform is a
          service-powered community that offers a variety of opportunities for
          every member and supports local artisans by giving them a platform to
          showcase their abilities and promote their work on the platform. We
          are also committed to reforestation efforts because we are aware of
          how deforestation affects Nigeria's natural resources. BetaHome.ng
          greatly reduces its environmental impact by utilizing only products
          made from sustainably sourced resources.
        </p>
      </div>
    </div>
  );
};

export default VideoPlayer;
